import { LoadingError } from "package:/components/elements/LoadingError";
import { Loader } from "package:/components/elements/loader";
import type { useApi } from "package:/composables/useApi";
import { error } from "package:/utils";
import type { ClassNameValue } from "tailwind-merge";
import { type VNode, createError, defineComponent, effect } from "#imports";

/**
 * SSR friendly dynamic data loading
 */
export const Dynamic = defineComponent(
  async (
    props: {
      /**
       * Classes to apply on the loading state.
       */
      class?: ClassNameValue;
      /**
       * The result of a useApi/useAsync call.
       */
      data: ReturnType<typeof useApi>;
      /**
       * The fallback component to display when an error occured.
       */
      error?: () => VNode;
      /**
       * Wether to thrown the error and display a fullscreen error page.
       */
      throw?: boolean;
      /**
       * Disabled ssr (default true)
       */
      ssr?: boolean;
    },
    { slots },
  ) => {
    if (props.ssr !== false && !process.browser) {
      // on the server, wait for the data to be fetched
      await props.data.suspense();
    }

    effect(() => {
      if (props.throw && props.data.error.value) {
        throw createError(props.data.error.value);
      }
    });

    return () =>
      props.data.error.value ? (
        props.error?.() || <LoadingError />
      ) : props.data.pending.value === true ? (
        <div class={props.class}>
          <Loader />
        </div>
      ) : props.data.data.value ? (
        slots.default?.()
      ) : (
        <LoadingError />
      );
  },
  {
    name: "Dynamic",
    props: ["data", "class", "throw", "error", "ssr"],
  },
);
