import errorGraphciShort from "assets:/error-graphic-short.svg?url";
import { twMerge } from "tailwind-merge";
import { useTranslations } from "#imports";

export function LoadingError(props: { class?: string }) {
  const t = useTranslations();

  return (
    <div class={twMerge("@container", props.class)}>
      <span>{t("error.500.title")}</span>
      <span class="hidden py-4 @xl:block">
        <img
          loading="lazy"
          decoding="async"
          src={errorGraphciShort}
          alt={errorGraphciShort}
        />
      </span>
    </div>
  );
}
