import loadingAnim from "assets:/greentrails_loader.riv?buffer";
import { twMerge } from "tailwind-merge";
import "./Animation";

export const Loader = (props: { class?: string }) => (
  <div
    class={twMerge(
      "flex h-full w-full items-center justify-center opacity-50",
      props.class,
    )}
  >
    <a-animation
      class="block h-[100px] w-[100px]"
      width={100}
      height={100}
      src={loadingAnim.type === "riv" && loadingAnim.uri}
      stateMachine="State Machine 1"
    />
  </div>
);
